import { baseUrl } from "./baseUrl";
import { get, post } from "./http";

// 检测信用代码
export const checkRegister = (param) =>
  get(baseUrl + "/blade-zb/index/checkRegister", param);

// 下拉框
export const queryGysRegisterDict = (param) =>
  get(baseUrl + "/blade-zb/index/queryGysRegisterDict", param);

export const registerGys = (param) =>
  post(baseUrl + "/blade-zb/index/registerGys", param);
