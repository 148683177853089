<template>
  <div class="register">
    <div class="nav">{{ title }}</div>
    <!-- <img class="nav" :src="require('./static/image/title.png')" alt="" /> -->
    <div class="item1" v-if="tabIndex == 0">
      <div class="process">
        <div class="process_div">
          <img :src="require('./static/image/process1.png')" alt="" />
          <div class="active">1、注册账户</div>
        </div>
        <div class="process_div">
          <img :src="require('./static/image/process2_n.png')" alt="" />
          <div>2、完善资料</div>
        </div>
        <div class="process_div">
          <img :src="require('./static/image/process3_n.png')" alt="" />
          <div>3、上传证照</div>
        </div>
      </div>
      <Form
        ref="form1"
        :model="form1"
        label-position="right"
        :rules="form1Rules"
        :label-width="form.labelWidth1"
      >
        <FormItem label="用户校验" required prop="zzjgshxy">
          <Input
            v-model="form1.zzjgshxy"
            placeholder="组织机构代码或统一社会信用代码"
            size="large"
            :style="[{ width: form.inputWidth1 }]"
          ></Input>
          <!-- icon="ios-power" -->
          <!-- <Button
            class="check"
            type="success"
            :loading="loading"
            @click="checkRegister()"
          >
            <span v-if="!loading">检测</span>
          </Button> -->
        </FormItem>
        <FormItem
          label="用户名"
          required
          prop="username"
          :style="[{ 'margin-bottom': '24px' }]"
        >
          <Input
            v-model="form1.username"
            placeholder="请输入用户名"
            size="large"
            :style="[{ width: form.inputWidth1 }]"
          ></Input>
        </FormItem>
        <FormItem
          label="密码"
          required
          prop="passward"
          password="true"
          :style="[{ 'margin-bottom': '24px' }]"
        >
          <Input
            v-model="form1.passward"
            placeholder="请输入密码"
            :style="[{ width: form.inputWidth1 }]"
            size="large"
            type="password"
            :password="true"
          ></Input>
        </FormItem>
        <FormItem label="确认密码" required prop="oncePassward">
          <Input
            v-model="form1.oncePassward"
            placeholder="请再次输入密码"
            :style="[{ width: form.inputWidth1 }]"
            size="large"
          ></Input>
        </FormItem>
      </Form>
      <div class="buttons">
        <div class="button_type1" @click="item1Next()">下一步</div>
      </div>
    </div>
    <div class="item2" v-if="tabIndex == 1">
      <div class="item2_div">
        <div class="process">
          <div class="process_div">
            <img :src="require('./static/image/process1.png')" alt="" />
            <div class="active">1、注册账户</div>
          </div>
          <div class="process_div">
            <img :src="require('./static/image/process2.png')" alt="" />
            <div class="active">2、完善资料</div>
          </div>
          <div class="process_div">
            <img :src="require('./static/image/process3_n.png')" alt="" />
            <div>3、上传证照</div>
          </div>
        </div>
        <Form
          ref="form2"
          :model="form2"
          label-position="right"
          :rules="form2Rules"
        >
          <div class="title">
            <div class="line"></div>
            <div>基本资料</div>
            <div class="line"></div>
          </div>
          <div class="row">
            <FormItem
              label="组织机构代码或统一社会信用代码"
              required
              prop="zzjgshxy"
              :label-width="form.labelWidth2_1"
            >
              <Input
                v-model="form2.zzjgshxy"
                placeholder="请输入"
                disabled
                readonly
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem
              label="供应商名称(中文)"
              required
              prop="gysmc"
              :label-width="form.labelWidth2_2"
            >
              <Input
                v-model="form2.gysmc"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="成立日期" :label-width="form.labelWidth2_3">
              <DatePicker
                type="date"
                :value="form2.clrq"
                format="yyyy-MM-dd"
                placeholder="请选择"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
                @on-change="onchangeTime('clrq', 0, $event)"
              ></DatePicker>
            </FormItem>
          </div>
          <dir class="row">
            <FormItem
              label="供应商地址"
              required
              prop="gysdz"
              :label-width="form.labelWidth2_1"
            >
              <!-- <Input
                v-model="form2.gysdz"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input> -->
              <Cascader
                not-found-text="省/市/区"
                :data="addressAll"
                :value="form2.gysdz"
                size="large"
                @on-change="addressChange"
                :style="[{ width: form.inputWidth2 }]"
              ></Cascader>
            </FormItem>
            <FormItem
              label="供应商详细地址"
              required
              prop="gysxxdz"
              :label-width="form.labelWidth2_2"
            >
              <Input
                v-model="form2.gysxxdz"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="邮政编码" :label-width="form.labelWidth2_3">
              <Input
                :maxlength="6"
                v-model="form2.yzbm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </dir>
          <div class="row">
            <FormItem
              label="供应商类型"
              required
              prop="gyslx"
              :label-width="form.labelWidth2_1"
            >
              <!-- v-if="isIE == false" -->
              <Select
                v-model="form2.gyslx"
                :style="[{ width: form.inputWidth2 }]"
                size="large"
              >
                <Option
                  :value="item.dictKey"
                  v-for="(item, index) in supplierType"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
              <!-- <select
                :style="[{ height: '38px', width: form.inputWidth2,border:'1px solid rgba(0,0,0,0.2)',borderRadius:'5px' }]"
                name=""
                id=""
                v-model="form2.gyslx"
                v-if="isIE == true"
              >
                <option
                  :value="item.dictKey"
                  v-for="(item, index) in supplierType"
                  :key="index"
                >
                  {{ item.dictValue }}
                </option>
              </select> -->
            </FormItem>
            <!-- <FormItem label="供应商类别" :label-width="form.labelWidth2_2">
              <Select
                v-model="form2.gyslb"
                :style="[{ width: form.inputWidth2 }]"
                size="large"
              >
                <Option
                  :value="item.dictKey"
                  v-for="(item, index) in supplierSize"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
            </FormItem> -->
            <FormItem label="企业网址" :label-width="form.labelWidth2_2">
              <Input
                :maxlength="6"
                v-model="form2.qywz"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="传真号码" :label-width="form.labelWidth2_3">
              <Input
                v-model="form2.czhm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </div>
          <div class="row">
            <FormItem label="企业邮箱" :label-width="form.labelWidth2_1">
              <Input
                v-model="form2.qyyx"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="企业电话" :label-width="form.labelWidth2_2">
              <Input
                v-model="form2.qydh"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="注册资本" :label-width="form.labelWidth2_3">
              <Input
                v-model="form2.zczb"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </div>
          <div class="row">
            <FormItem label="从业人员(人数)" :label-width="form.labelWidth2_1">
              <Input
                type="number"
                v-model="form2.cyry"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="经济性质" :label-width="form.labelWidth2_2">
              <Select
                v-model="form2.jjxz"
                :style="[{ width: form.inputWidth2 }]"
                size="large"
              >
                <Option
                  :value="item.dictKey"
                  v-for="(item, index) in economicNature"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
            </FormItem>
            <FormItem
              label="企业认定行业划分"
              :label-width="form.labelWidth2_3"
            >
              <Select
                v-model="form2.qyrdhyhf"
                :style="[{ width: form.inputWidth2 }]"
                size="large"
              >
                <Option
                  :value="item.dictKey"
                  v-for="(item, index) in industryType"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
            </FormItem>
          </div>
          <div class="row">
            <FormItem
              label="国民经济行业名称"
              :label-width="form.labelWidth2_1"
            >
              <Input
                v-model="form2.gmjjhymc"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem
              label="营业收入/年"
              prop="yysr"
              :label-width="form.labelWidth2_2"
            >
              <Input
                v-model="form2.yysr"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem label="供应商简介" :label-width="form.labelWidth2_3">
              <Input
                class="textarea"
                v-model="form2.gysjj"
                type="textarea"
                placeholder="请输入"
                maxlength="200"
                show-word-limit
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </div>
          <div class="title">
            <div class="line"></div>
            <div>法定代表人基本信息</div>
            <div class="line"></div>
          </div>
          <div class="row">
            <FormItem
              label="法定代表人姓名"
              required
              prop="fddbrxm"
              :label-width="form.labelWidth2_1"
            >
              <Input
                v-model="form2.fddbrxm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem
              label="手机号码"
              required
              prop="frsjhm"
              :label-width="form.labelWidth2_2"
            >
              <Input
                v-model="form2.frsjhm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem
              label="法人证件号码"
              required
              prop="frzjhm"
              :label-width="form.labelWidth2_3"
            >
              <Input
                v-model="form2.frzjhm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </div>
          <div class="title">
            <div class="line"></div>
            <div>联系人基本信息</div>
            <div class="line"></div>
          </div>
          <div class="row">
            <FormItem
              label="联系人姓名"
              required
              prop="lxrxm"
              :label-width="form.labelWidth2_1"
            >
              <Input
                v-model="form2.lxrxm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
            <FormItem
              label="手机号码"
              required
              prop="lxrsjhm"
              :label-width="form.labelWidth2_2"
            >
              <Input
                v-model="form2.lxrsjhm"
                placeholder="请输入"
                size="large"
                :style="[{ width: form.inputWidth2 }]"
              ></Input>
            </FormItem>
          </div>
        </Form>
        <div class="buttons">
          <div class="button_type2" @click="item2Prev()">返回上一步</div>
          <div class="button_type1" @click="item2Next()">下一步</div>
        </div>
      </div>
    </div>
    <div class="item3" v-if="tabIndex == 2">
      <div class="item3_div">
        <div class="process">
          <div class="process_div">
            <img :src="require('./static/image/process1.png')" alt="" />
            <div class="active">1、注册账户</div>
          </div>
          <div class="process_div">
            <img :src="require('./static/image/process2.png')" alt="" />
            <div class="active">2、完善资料</div>
          </div>
          <div class="process_div">
            <img :src="require('./static/image/process3.png')" alt="" />
            <div class="active">3、上传证照</div>
          </div>
        </div>
        <Form
          ref="form3"
          :model="form3"
          label-position="right"
          :rules="form3Rules"
          :label-width="form.labelWidth3"
        >
          <div class="title">
            <div></div>
            <div>营业执照或统一社会信用代码证</div>
            <div></div>
          </div>
          <div class="row">
            <div class="row_div row_div_upload">
              <div class="upload_title"><span>*</span> 营业执照证件附件</div>
              <div class="upload">
                <Upload
                  type="drag"
                  :action="uploadAction"
                  :on-success="uploadSuccess1"
                  :show-upload-list="false"
                >
                  <div class="upload_y" v-if="form3.yyzz">
                    <img :src="form3.yyzz" alt="" />
                  </div>
                  <div class="upload_n" v-else>
                    <img :src="require('./static/image/upload.png')" alt="" />
                    <p>上传证照附件</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div class="row_div row_div_input">
              <FormItem
                label="证件号码"
                required
                prop="yyzzzjhm"
                :style="[{ 'margin-bottom': '24px' }]"
              >
                <Input
                  v-model="form3.yyzzzjhm"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem
                label="发证机构"
                required
                prop="yyzzfzjg"
                :style="[{ 'margin-bottom': '24px' }]"
              >
                <Input
                  v-model="form3.yyzzfzjg"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="有效期" prop="">
                <div class="db_time">
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="开始时间"
                      size="large"
                      :value="form3.yyzzyxq[0]"
                      :style="[{ width: form.inputWidth3_2 }]"
                      @on-change="onchangeTime('yyzzyxq', 0, $event)"
                    ></DatePicker>
                  </div>
                  <div class="db_time_line">-</div>
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="结束时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      :value="form3.yyzzyxq[1]"
                      @on-change="onchangeTime('yyzzyxq', 1, $event)"
                    ></DatePicker>
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
          <div class="title">
            <div></div>
            <div>组织机构证照信息</div>
            <div></div>
          </div>
          <div class="row">
            <div class="row_div row_div_upload">
              <div class="upload_title">组织机构证照</div>
              <div class="upload">
                <Upload
                  type="drag"
                  :action="uploadAction"
                  :on-success="uploadSuccess2"
                  :show-upload-list="false"
                >
                  <div class="upload_y" v-if="form3.zzjg">
                    <img :src="form3.zzjg" alt="" />
                  </div>
                  <div class="upload_n" v-else>
                    <img :src="require('./static/image/upload.png')" alt="" />
                    <p>上传证照附件</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div class="row_div row_div_input">
              <FormItem
                label="国税登记号"
                :style="[{ 'margin-bottom': '24px' }]"
              >
                <Input
                  v-model="form3.zzjggsdjh"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="发证机构" :style="[{ 'margin-bottom': '24px' }]">
                <Input
                  v-model="form3.zzjgfzjg"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="有效期" prop="">
                <div class="db_time">
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="开始时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      :value="form3.zzjgyxq[0]"
                      @on-change="onchangeTime('zzjgyxq', 0, $event)"
                    ></DatePicker>
                  </div>
                  <div class="db_time_line">-</div>
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="结束时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      :value="form3.zzjgyxq[1]"
                      @on-change="onchangeTime('zzjgyxq', 1, $event)"
                    ></DatePicker>
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
          <div class="title">
            <div></div>
            <div>税务登记信息</div>
            <div></div>
          </div>
          <div class="row">
            <div class="row_div row_div_upload">
              <div class="upload_title">证照附件</div>
              <div class="upload">
                <Upload
                  type="drag"
                  :show-upload-list="false"
                  :action="uploadAction"
                  :on-success="uploadSuccess3"
                >
                  <div class="upload_y" v-if="form3.zzfj1">
                    <img :src="form3.zzfj1" alt="" />
                  </div>
                  <div class="upload_n" v-else>
                    <img :src="require('./static/image/upload.png')" alt="" />
                    <p>上传证照附件</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div class="row_div row_div_input">
              <FormItem
                label="国税登记号"
                :style="[{ 'margin-bottom': '24px' }]"
              >
                <Input
                  v-model="form3.zzfj1gsdjh"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="发证机构" :style="[{ 'margin-bottom': '24px' }]">
                <Input
                  v-model="form3.zzfj1fzjg"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="有效期" prop="">
                <div class="db_time">
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="开始时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      @on-change="onchangeTime('zzfj1gyxq', 0, $event)"
                    ></DatePicker>
                  </div>
                  <div class="db_time_line">-</div>
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="结束时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      @on-change="onchangeTime('zzfj1gyxq', 1, $event)"
                    ></DatePicker>
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
          <div class="row">
            <div class="row_div row_div_upload">
              <div class="upload_title">证照附件</div>
              <div class="upload">
                <Upload
                  type="drag"
                  :show-upload-list="false"
                  :action="uploadAction"
                  :on-success="uploadSuccess4"
                >
                  <div class="upload_y" v-if="form3.zzfj2">
                    <img :src="form3.zzfj2" alt="" />
                  </div>
                  <div class="upload_n" v-else>
                    <img :src="require('./static/image/upload.png')" alt="" />
                    <p>上传证照附件</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div class="row_div row_div_input">
              <FormItem
                label="国税登记号"
                :style="[{ 'margin-bottom': '24px' }]"
              >
                <Input
                  v-model="form3.zzfj2gsdjh"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="发证机构" :style="[{ 'margin-bottom': '24px' }]">
                <Input
                  v-model="form3.zzfj2fzjg"
                  placeholder="请输入"
                  size="large"
                  :style="[{ width: form.inputWidth3_1 }]"
                ></Input>
              </FormItem>
              <FormItem label="有效期" prop="">
                <div class="db_time">
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="开始时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      @on-change="onchangeTime('zzfj2gyxq', 0, $event)"
                    ></DatePicker>
                  </div>
                  <div class="db_time_line">-</div>
                  <div class="db_time_div">
                    <DatePicker
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="结束时间"
                      size="large"
                      :style="[{ width: form.inputWidth3_2 }]"
                      @on-change="onchangeTime('zzfj2gyxq', 1, $event)"
                    ></DatePicker>
                  </div>
                </div>
              </FormItem>
            </div>
          </div>
        </Form>
        <div class="buttons">
          <div class="button_type2" @click="item3Prev()">返回上一步</div>
          <div class="button_type1" @click="item3Submit()">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import {
  checkRegister,
  queryGysRegisterDict,
  registerGys,
} from "../../request/register";
import { baseUrl } from "../../request/baseUrl";
import addressJson from "../../assets/json/provinces.js";
export default {
  name: "register",
  data() {
    const validateOncePassward = (rule, value, callback) => {
      if (value !== this.form1.passward) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!/^1[34578]\d{9}$/.test(value)) {
        callback("手机号格式不正确");
      } else {
        callback();
      }
    };
    const validateIdCard = (rule, value, callback) => {
      if (
        !/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        )
      ) {
        callback("证件格式不正确");
      } else {
        callback();
      }
    };
    return {
      tabIndex: 0,
      isIE: "",
      addressAll: [],
      form: {
        labelWidth1: 0,
        inputWidth1: 0,
        labelWidth2_1: 0,
        labelWidth2_2: 0,
        labelWidth2_3: 0,
        inputWidth2: 0,
        labelWidth3: 0,
        inputWidth3_1: 0,
        inputWidth3_2: 0,
      },
      loading: false,
      supplierType: [],
      economicNature: [],
      industryType: [],
      supplierSize: [],
      uploadAction: baseUrl + "/blade-file/system/upload",
      form1: {
        zzjgshxy: "",
        username: "",
        passward: "",
        oncePassward: "",
      },
      form2: {
        zzjgshxy: "",
        gysmc: "",
        yzbm: "",
        gysdz: [],
        gysxxdz: "",
        gyslx: "",
        qywz: "",
        // gyslb: "",
        czhm: "",
        qyyx: "",
        qydh: "",
        zczb: "",
        clrq: "",
        jjxz: "",
        qyrdhyhf: "",
        gmjjhymc: "",
        yysr: "",
        gysjj: "",
        cyry: "",
        fddbrxm: "",
        frsjhm: "",
        frzjhm: "",
        lxrxm: "",
        lxrsjhm: "",
      },
      form3: {
        yyzz: "",
        yyzzzjhm: "",
        yyzzfzjg: "",
        yyzzyxq: ["", ""],
        zzjg: "",
        zzjggsdjh: "",
        zzjgfzjg: "",
        zzjgyxq: ["", ""],
        zzfj1: "",
        zzfj1gsdjh: "",
        zzfj1fzjg: "",
        zzfj1gyxq: ["", ""],
        zzfj2: "",
        zzfj2gsdjh: "",
        zzfj2fzjg: "",
        zzfj2gyxq: ["", ""],
      },
      form1Rules: {
        zzjgshxy: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        passward: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 12,
            message: "请输入6-12位密码",
            trigger: "blur",
          },
        ],
        oncePassward: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          { validator: validateOncePassward, trigger: "blur" },
        ],
      },
      form2Rules: {
        zzjgshxy: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        gysmc: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        gysdz: [
          {
            required: true,
            message: "请输入",
            type: "array",
            trigger: "blur",
          },
        ],
        gysxxdz: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        gyslx: [
          {
            required: true,
            type: "number",
            message: "请选择",
            trigger: "blur",
          },
        ],
        fddbrxm: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        frsjhm: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          { validator: validatePhone, trigger: "blur" },
        ],
        frzjhm: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          { validator: validateIdCard, trigger: "blur" },
        ],
        lxrxm: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        lxrsjhm: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          { validator: validatePhone, trigger: "blur" },
        ],
      },
      form3Rules: {
        yyzzzjhm: [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur",
          },
        ],
        yyzzfzjg: [
          {
            required: true,
            message: "请输入发证机构",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.title.title,
    }),
  },
  mounted() {
    //   动态设置form表单
    var _this = this;
    _this.getInputWidth();
    window.addEventListener("resize", () => {
      _this.getInputWidth();
    });
    // 下拉框
    this.queryGysRegisterDict();
    // 省市区数据
    this.addressAll = JSON.parse(JSON.stringify(addressJson));
    this.isIEMethod();
  },
  methods: {
    isIEMethod() {
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        this.isIE = true;
      } else {
        this.isIE = false;
      }
    },
    // 获取输入框宽度
    getInputWidth() {
      // form1
      this.form.inputWidth1 = Math.ceil(297 * this.$htmlSize) + "px";
      this.form.labelWidth1 = Math.ceil(240 * this.$htmlSize);
      // form2
      this.form.inputWidth2 = Math.ceil(368 * this.$htmlSize) + "px";
      this.form.labelWidth2_1 = Math.ceil(272 * this.$htmlSize);
      this.form.labelWidth2_2 = Math.ceil(195 * this.$htmlSize);
      this.form.labelWidth2_3 = Math.ceil(176 * this.$htmlSize);
      // form3
      this.form.inputWidth3_1 = Math.ceil(368 * this.$htmlSize) + "px";
      this.form.inputWidth3_2 = Math.ceil(150 * this.$htmlSize) + "px";
      this.form.labelWidth3 = Math.ceil(200 * this.$htmlSize);
    },
    // 检测信用代码以及用户名
    item1Next() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          checkRegister({
            xyNumber: this.form1.zzjgshxy,
            account: this.form1.username,
          }).then((res) => {
            if (res.code == 200) {
              this.form2.zzjgshxy = this.form1.zzjgshxy;
              this.tabIndex++;
            } else {
              this.$Message.error(res.msg);
            }
          });
        } else {
          this.$Message.error("输入有误！");
        }
      });
    },
    item2Prev() {
      this.tabIndex--;
    },
    item2Next() {
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          this.tabIndex++;
        } else {
          this.$Message.error("输入有误！");
        }
      });
    },
    item3Prev() {
      this.tabIndex--;
    },
    // 选择省市区
    addressChange(value, selectedData) {
      this.form2.gysdz = value;
    },
    // 图片上传
    uploadSuccess1(response, file, fileList) {
      this.form3.yyzz = response.data.downloadPath;
    },
    uploadSuccess2(response, file, fileList) {
      this.form3.zzjg = response.data.downloadPath;
    },
    uploadSuccess3(response, file, fileList) {
      this.form3.zzfj1 = response.data.downloadPath;
    },
    uploadSuccess4(response, file, fileList) {
      this.form3.zzfj2 = response.data.downloadPath;
    },
    item3Submit() {
      if (this.form3.yyzz == "") {
        this.$Message.error("请上传营业执照！");
        return;
      }
      this.$refs["form3"].validate((valid) => {
        if (valid) {
          registerGys({
            account: this.form1.username,
            password: this.form1.passward,
            checkPassword: this.form1.oncePassward,
            xyNumber: this.form2.zzjgshxy,
            name: this.form2.gysmc,
            postalCode: this.form2.yzbm,
            province: this.form2.gysdz[0],
            city: this.form2.gysdz[1],
            county: this.form2.gysdz[2],
            address: this.form2.gysxxdz,
            registerDate: this.form2.clrq,
            registerType: this.form2.gyslx,
            // supplierSize: this.form2.gyslb,
            qywz: this.form2.qywz,
            fax: this.form2.czhm,
            qyEmail: this.form2.qyyx,
            qyPhone: this.form2.qydh,
            registerMoney: this.form2.zczb,
            cyWorkerNum: this.form2.cyry,
            economicNature: this.form2.jjxz,
            industryType: this.form2.qyrdhyhf,
            gmjjIndustry: this.form2.gmjjhymc,
            yearIncome: this.form2.yysr,
            gysInfo: this.form2.gysjj,
            legalIdCard: this.form2.frzjhm,
            legalPhone: this.form2.frsjhm,
            legal: this.form2.fddbrxm,
            contactName: this.form2.lxrxm,
            contactPhone: this.form2.lxrsjhm,
            yyNumber: this.form3.yyzzzjhm,
            yyNumberFile: this.form3.yyzz,
            yyIssueAuthority: this.form3.yyzzfzjg,
            yyStartDate: this.form3.yyzzyxq[0],
            yyEndDate: this.form3.yyzzyxq[1],
            orgNumber: this.form3.zzjggsdjh,
            orgNumberFile: this.form3.zzjg,
            orgIssueAuthority: this.form3.zzjgfzjg,
            orgStartDate: this.form3.zzjgyxq[0],
            orgEndDate: this.form3.zzjgyxq[1],
            gsNumber: this.form3.zzfj1gsdjh,
            gsNumberFile: this.form3.zzfj1,
            gsIssueAuthority: this.form3.zzjgfzjg,
            gsStartDate: this.form3.zzfj1gyxq[0],
            gsEndDate: this.form3.zzfj1gyxq[1],
            dsNumber: this.form3.zzfj2gsdjh,
            dsNumberFile: this.form3.zzfj2,
            dsIssueAuthority: this.form3.zzfj2fzjg,
            dsStartDate: this.form3.zzfj2gyxq[0],
            dsEndDate: this.form3.zzfj2gyxq[1],
          }).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$router.push({
                path: "/",
                query: {
                  id: sessionStorage.getItem("id"),
                },
              });
              setTimeout(() => {
                window.open(sessionStorage.getItem("loginUrl"), "_blank");
              }, 1000);
            } else {
              this.$Message.error(res.msg);
            }
          });
        } else {
          this.$Message.error("输入有误！");
        }
      });
    },
    // 时间选择
    onchangeTime(type, number, $event) {
      if (type == "clrq") {
        this.form2.clrq = $event;
      }
      if (type == "yyzzyxq") {
        this.form3.yyzzyxq[number] = $event;
      }
      if (type == "zzjgyxq") {
        this.form3.zzjgyxq[number] = $event;
      }
      if (type == "zzfj1gyxq") {
        this.form3.zzfj1gyxq[number] = $event;
      }
      if (type == "zzfj2gyxq") {
        this.form3.zzfj2gyxq[number] = $event;
      }
    },
    // 获取下拉框
    queryGysRegisterDict() {
      queryGysRegisterDict().then((res) => {
        // 供应商类型
        this.supplierType = res.data.supplierType;
        // 经济性质
        this.economicNature = res.data.economicNature;
        //  所属行业
        this.industryType = res.data.industryType;
        // 供应商类别
        this.supplierSize = res.data.supplierSize;
      });
    },
  },
};
</script>
<style lang="less">
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.register {
  // width: 1920px;
  height: 1080px;
  background-image: url("./static/image/login.png");
  background-size: 100% 100%;
  position: relative;

  .nav {
    width: 100%;
    position: absolute;
    top: 105px;
    left: 50%;
    transform: translateX(-50%);
    .center();
    font-size: 58px;
    color: white;
    font-weight: bold;
  }
  .item1,
  .item2,
  .item3 {
    position: absolute;
    top: 217px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding-top: 32px;
    .process {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      .process_div:nth-child(1) {
        width: 223px;
        height: 64px;
        margin-right: -25px;
      }
      .process_div:nth-child(2) {
        width: 237px;
        height: 64px;
        margin-right: -25px;
      }
      .process_div:nth-child(3) {
        width: 224px;
        height: 64px;
      }
      .process_div {
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        div {
          .center();
          width: 100%;
          height: 100%;
          font-size: 20px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #696d7d;
          position: absolute;
          left: 0;
          top: 0;
        }
        .active {
          color: #ffffff;
        }
      }
    }
    .buttons {
      .center();
      div {
        cursor: pointer;
      }
      .button_type1 {
        width: 284px;
        height: 52px;
        background: #2957ff;
        font-size: 14px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        .center();
      }
      .button_type2 {
        width: 142px;
        height: 52px;
        background: #b7bcce;
        font-size: 14px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        .center();
        margin-right: 16px;
      }
    }
  }
  .item1 {
    width: 698px;
    height: 768px;
    background: #ffffff;
    .process {
      margin-bottom: 100px;
    }
    .check {
      margin-left: 20px;
      // .center();
      // position: absolute;
      // right: 44px;
      // top: 50%;
      // transform: translateY(-50%);
    }
  }
  .item2 {
    width: 1816px;
    height: 831px;
    background: #ffffff;
    overflow-y: auto;

    .process {
      margin-bottom: 44px;
    }
    .item2_div {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 32px;
      .title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(1) {
          width: 76px;
          height: 1px;
          background: #dadce3;
        }
        div:nth-child(2) {
          font-size: 24px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
          margin: 0 12px;
        }
        div:nth-child(3) {
          width: 76px;
          height: 1px;
          background: #dadce3;
        }
      }
      .row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
        div {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
        }
        .ivu-form-item-content {
          margin-left: 0 !important;
        }
        .ivu-select-selection {
          width: 100% !important;
        }
        .ivu-date-picker-rel {
          width: 100% !important;
        }
        .ivu-picker-panel-body {
          display: flex;
          flex-flow: column;
        }
        .ivu-date-picker-cells {
          display: flex;
          flex-flow: row wrap;
        }
        .textarea {
          position: absolute;
          left: 0;
          top: -20px;
        }
      }
      .buttons {
        margin-top: 68px;
      }
    }
  }
  .item3 {
    width: 1816px;
    height: 831px;
    background: #ffffff;
    overflow-y: auto;

    .process {
      margin-bottom: 44px;
    }

    .item3_div {
      width: 100%;
      box-sizing: border-box;
      padding-bottom: 32px;
      .title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(1) {
          width: 76px;
          height: 1px;
          background: #dadce3;
        }
        div:nth-child(2) {
          font-size: 24px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
          margin: 0 12px;
        }
        div:nth-child(3) {
          width: 76px;
          height: 1px;
          background: #dadce3;
        }
      }
      .row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        .row_div {
          .upload_title {
            width: 258px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #232428;
            margin-bottom: 12px;
            span {
              color: #ff2424;
            }
          }
          .upload {
            width: 258px;
            height: 150px;
            .ivu-upload {
              width: 258px;
              height: 150px;
            }
            .upload_n {
              width: 100%;
              height: 100%;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              img {
                width: 48px;
                height: 33px;
              }
              p {
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #232428;
                margin-top: 10px;
              }
            }
            .upload_y {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .db_time {
            width: 368px;
            height: 55px;
            box-sizing: border-box;
            border: 1px solid #dcdee2;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            border-radius: 5px;
            &:hover {
              box-shadow: 0 0 0 1px rgba(45, 140, 240, 0.8);
            }
            .db_time_div {
              flex: 1;
            }
            .db_time_line {
              flex: 0.3;
              text-align: center;
            }
            .ivu-input-wrapper {
              border: none !important;
              background-color: transparent !important;

              input {
                border: none !important;
                outline: none !important;
                background-color: transparent !important;
                box-shadow: none;
              }
            }
          }
        }
        .row_div_upload {
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;
        }
        .row_div_input {
          flex: 1.5;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .buttons {
        margin-top: 68px;
      }
    }
  }
}
</style>